import { Component } from '@angular/core';

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component {
  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }
}
