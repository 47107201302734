import { Component, OnInit, Input, Inject, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ClipsService } from '../clips.service';
import { ContentClip } from '../../models/content-clip.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Validators, FormControl} from '@angular/forms';
import { CustomMessageComponent } from 'src/app/utils/custom-message/custom-message.component';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/user/user.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';


@Component({
  selector: 'app-clip-upload',
  templateUrl: './clip-upload.component.html',
  styleUrls: ['./clip-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClipUploadComponent implements OnInit {
  @Input() type = 'video';
  @Output() uploaded = new EventEmitter();
  clips: ContentClip[] = [];
  isLoadingResults = false;
  isLinear = true;
  isCompleted = false;
  uploadError = false;
  files: any = [];
  percentDone = 0;
  source: FormControl;
  defaultOptions: any;
  needsDuration = true;
  clipError = false;
  isUploadValid = false;
 
  externalSourceError = true;
  selectedIndex = 0;
  transcodingInterval: any;

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClipUploadComponent>,
    private clipsService: ClipsService,
    public userService: UserService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.type = typeof data.type === 'undefined' ? this.type : data.type;
      console.log(data);
      
      this.defaultOptions = {
        id: null,
        name: '',
        description: '',
        volume: 1,
        mute: false,
        source: '',
        duration: null,
        mimeType: '',
        clipType: '',
        preview: {
            large: {
              url: ''
            },
            small: {
              url: ''
            },
        },
        uploadDate: null,
        size: null,
        tags: [],
        resolution: {
          width: 0,
          height: 0,
        }
      };
  }

  ngOnInit( ) {
    this.source = new FormControl('', { updateOn: 'change', validators: [Validators.required, Validators.pattern(CustomUtils.urlRegex2)]} );
    this.source.valueChanges.subscribe((value) => { this.checkLink(value); });
  }

  async onSelect(event) {

    this.files = event.addedFiles;
    this.isCompleted = false;

    if (this.files.length > 0) {
      this.isLoadingResults = true;
      this.files.reduce((p, file: any, index: number) => {
        file.progress = 0;
        return p.then(() => {
          return new Promise((resolve, reject) => {
            this.clips[index] = new ContentClip();
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            this.clipsService.uploadFile( formData ).subscribe(( data ) => {
              if (data.status) {
                switch (data.status) {
                  case 'progress':
                  file.progress =  parseInt(data.message);
                  break;
                }
              }
              if (data.id) {
                this.clips[index] = data;
                this.uploadError = false;
                resolve(true);
              }
            }, (error) => {
              console.log(error);
              this.uploadError = true;
              resolve(false);
            });
          });
        });
      }, Promise.resolve()).then(()=>{
        this.isLoadingResults = false;
        this.isCompleted = true;
        this.getTranscodingStatus();
        console.log("all downloads completed")
      });
    } else {
      this.uploadErrorMessage();
    }
  }

  changeStep(event) {
    this.updateClip(this.selectedIndex);
    this.selectedIndex = event.selectedIndex;
    this.getTranscodingStatus();
  }

  deleteClip(index: number) {
    this.clipsService.deleteClip(this.clips[index].id).subscribe((result) => {
        this.snackBar.open(this.clips[index].name + ' ' + $localize `odstraněn`, '', {
            duration: 3000,
        });
    });
  }

  getTranscodingStatus() {
    if(this.transcodingInterval) {
      clearInterval(this.transcodingInterval);
    }
    if(this.clips[this.selectedIndex].isTranscoding) {
      this.transcodingInterval = setInterval(() => {
          this.getClipData();
      }, 10000);
    }
  }
  getClipData() {
    this.clipsService.fetchClipById(this.clips[this.selectedIndex].id).subscribe((data: ContentClip) => {
      if (!data.isTranscoding) {
        const index = this.clips.findIndex(item => item.id === data.id );
        //update forms values
        data.name =  this.clips[index].name;
        data.description =  this.clips[index].description;
        data.tags =  this.clips[index].tags;
        this.clips[index] = Object.assign( this.clips[index], data);
        clearInterval(this.transcodingInterval);
      }
    })
  }
  updateClip(index: number) {
    this.clipsService.updateClip(this.clips[index]).subscribe((result) => {
      this.snackBar.open(this.clips[index].name + ' ' + $localize `uložen`, '', {
        duration: 3000,
      });
    }, (error) => {
      this.snackBar.open(this.clips[index].name + ' ' +  $localize `se nepodařilo uložit`, '', {
        duration: 3000,
      });
    });
  }

  updateClips() {
    let index = 0;
    this.clips.reduce((p, clip: any, index: number) => {
      return p.then(() => {
        return new Promise((resolve, reject) => {
          clip.uploadDate = new Date().toISOString();
          this.clipsService.updateClip(clip).subscribe((result) => {
            index++;
            this.snackBar.open(index + '/'+ this.clips.length + ' ' + $localize `uložen`, '', {
              duration: 3000,
            });
            this.dialogRef.close();
            resolve();
          }, (error) => {
            index++;
            this.snackBar.open(index + '/'+ this.clips.length + ' ' +  $localize `se nepodařilo uložit`, '', {
              duration: 3000,
            });
            resolve();
          });
      });
      });
    }, Promise.resolve()).then(()=>{
      this.clipsService.newClipUploaded.next(this.clips);
      this.uploaded.emit(this.clips);
      this.clipsService.updateClipsList();
      this.dialogRef.close();
    });
  }
  saveClip(index: number) {
    this.clips[index].uploadDate = new Date().toISOString();
    this.clipsService.saveClip(this.clips[index]).subscribe((result) => {
      if (result.clipId) {
        
        this.snackBar.open(this.clips[index].name + ' '+ $localize `uložen`, '', {
          duration: 3000,
        });
        this.clips[index].id = result.clipId;
        this.clipsService.newClipUploaded.next(this.clips);
        this.uploaded.emit(this.clips);
        this.clipsService.updateClipsList();
        this.dialogRef.close();
      } else {
        
        this.snackBar.open($localize `Nepodařilo se vytvořit klip`, '', {
          duration: 3000,
        });
      }
    }, (error) => {
      this.snackBar.open(this.clips[index].name + ' ' +  $localize `se nepodařilo uložit`, '', {
        duration: 3000,
      });
    });
  }

  checkLink(url: string) {
    const parsedUrl = this.parseUrl(url);
    if ( parsedUrl ) {
      // try to recognize type of server
        this.clips[0] = new ContentClip();
        this.clips[0] = Object.assign(this.clips[0], this.defaultOptions);
    
        //if ( this.type === 'external' ||  this.type === 'live' ) {
        if (parsedUrl.protocol == "http:" || parsedUrl.protocol == "https:") {
         
          switch (parsedUrl.hostname) {
            case 'www.youtube.com':
                this.setYoutubeClip(url, 0);
                break;
            case 'youtube.com':
                this.setYoutubeClip(url, 0);
                break;
            case 'youtu.be':
                this.setYoutubeClip(url, 0);
                break;
            default:
                this.otherHtml(url, 0);
          }
        }

        if (parsedUrl.protocol == "rtsp:") {
          this.clips[0].mimeType = 'application/x-rtsp';
          this.setStreamClip(0, url);
        }
        //}
    }
  }

  resetClip(index: number) {
    this.isCompleted = false;
    this.clips[index] = this.defaultOptions;
    this.source.setValue('', {emitEvent: false});
  }
  getProtocol(url: string) {
    if ( CustomUtils.testUrl(url) ) {
      let parsedUrl = new URL(url);
      return parsedUrl.protocol;
    }
  }
  
  parseUrl(url: string) {
    
    if ( CustomUtils.testUrl(url) ) {
      // parsing url
      let parsedUrl;
      try {
          const parseUrl = new URL(url);
          parsedUrl =   parseUrl;
      } catch (error) {
        parsedUrl = '';
          console.log(error);
      }
      return parsedUrl;
    }
  }

  setVideoClip(index: number) {
    this.clips[index].clipType = 'video';
  }
  setImageClip(index: number) {
    this.needsDuration = true;
    this.clips[index].clipType = 'image';
  }

  otherHtml(url, index: number) {
    const fileExtension = url.split('.').pop().split(/\#|\?/)[0];

    if (fileExtension) {
      switch (fileExtension) {
        case 'm3u8':
            this.clips[index].mimeType = 'application/x-mpegURL';
            this.setStreamClip(0, url);
            break;
        default:
            this.clips[index].mimeType = 'video/x-live';
            this.setHtmlClip(0, url);
      }
    }
    this.externalSourceError = false;
  }

  setHtmlClip(index: number, url: string) {
    this.clips[index].source = url;
    this.clips[index].mimeType = 'text/html';
    this.clips[index].clipType = 'html';
    this.needsDuration = true;
    this.clips[index].resolution.width = 1920;
    this.clips[index].resolution.height = 1080;

    this.clips[index].preview.large.url = './assets/img/placeholders/html-placeholder.png';
    this.clips[index].preview.small.url = './assets/img/placeholders/html-placeholder.png';

    this.isCompleted = true;
  }

  setStreamClip(index: number, url: string) {
    this.externalSourceError = false;
    this.needsDuration = true;
    this.clips[index].source = url;
    this.clips[index].resolution.width = 1920;
    this.clips[index].resolution.height = 1080;
    this.clips[index].clipType = 'live';

    this.isCompleted = true;
  }
  async setYoutubeClip(value: string, index: number) {
    try {
      this.clips[index].source = value;
      const videoId = value.split('v=')[1].split('&')[0];
      const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}`;

      this.clipsService.getYoutubeInfo(url,
        {params: {part: 'id,snippet,contentDetails', key: 'AIzaSyCyZfUtlGkP1CjiOaBZHuAVIMY9Fl7vp3E'}, responseType: 'json'}
      ).subscribe((youtubeVideo: any) => {

        if ( youtubeVideo.items.length > 0 ) {
          this.externalSourceError = false;

          if (youtubeVideo.items[0].snippet.liveBroadcastContent === 'live' ) {
              this.needsDuration = true;
              this.type = 'live'; // change to another upload preferences
              this.clips[index].mimeType = 'video/x-youtube-live';
              this.clips[index].clipType = 'live';
          } else {
              this.clips[index].mimeType = 'video/x-youtube';
              this.clips[index].clipType = 'youtube';
              this.clips[index].duration = this.YTDurationToSeconds(youtubeVideo.items[0].contentDetails.duration);
          }

          this.clips[index].name = youtubeVideo.items[0].snippet.title;
          this.clips[index].description = youtubeVideo.items[0].snippet.description;

          this.clips[index].preview.large.url = youtubeVideo.items[0].snippet.thumbnails.high.url;
          this.clips[index].preview.small.url = youtubeVideo.items[0].snippet.thumbnails.medium.url;

          if (youtubeVideo.items[0].contentDetails.definition === 'hd') {
              this.clips[index].resolution.width = 1920;
              this.clips[index].resolution.height = 1080;
          }
          this.isCompleted = true;

        } else {
          this.externalSourceError = true;
        }
      });

    } catch (err) {
      this.resetClip(0);
      console.log(err);
    }
  }

  updateClipProperties(videoElement: any, index: number) {
    this.clips[index].uploadDate = new Date().toISOString();
  }
  YTDurationToSeconds(ytDuration: string) {
    let match = ytDuration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    let matchSliced = match.slice(1).map((x) => {
      if (x != null) {
          return x.replace(/\D/, '');
      }
    });

    const hours = (parseInt(matchSliced[0], 10) || 0);
    const minutes = (parseInt(matchSliced[1], 10) || 0);
    const seconds = (parseInt(matchSliced[2], 10) || 0);

    return hours * 3600 + minutes * 60 + seconds;
  }
  checkFormValidation(event) {
    this.isUploadValid = event;
  }

  uploadErrorMessage() {
    const dialogRef = this.dialog.open(CustomMessageComponent, {
      width: '300px',
      panelClass: '',
      data: {message: $localize `Uploadovaný soubor není ve správném formátu`}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
          this.dialogRef.close();
      }
    });
  }
}
