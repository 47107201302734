import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Campaign } from '../../models/campaign.model';
import { DevicesService } from '../../devices/devices.service';
import { Tag, TagEvent } from 'src/app/models/tag.model';
import { TagsService } from 'src/app/tags/tags.service';
import { DevicePreview } from 'src/app/models/device-preview.model';

@Component({
  selector: 'app-campaign-devices',
  templateUrl: './campaign-devices.component.html',
  styleUrls: ['./campaign-devices.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CampaignDevicesComponent implements OnInit {
  @Input() campaign: Campaign;
  @Output() validated = new EventEmitter();
  @Output() selected = new EventEmitter();
  activeTags:Tag[] = [];
  constructor(private tagsService: TagsService, private devicesService: DevicesService) { }

  ngOnInit() {
    this.devicesService.devicesSelectedTagsSubject.subscribe(tags => {
      this.activeTags = [];
      this.activeTags = [...tags];
    })
  }

  selectedTags(tags: Tag[]) {
    this.devicesService.filterDevicesByTags(tags);
  }
  
  checkedTag(tagsEvent: TagEvent) {
    this.devicesService.checkDeviceByTags(tagsEvent);
    this.validate();
  }

  createNewTagFromSelection() {
    this.tagsService.openTagNewDialog('devices', this.campaign.selectedDevices);
  }
  
  checkDevice(devices: DevicePreview[]) {
    this.campaign.selectedDevices = [];

    devices.forEach((device) => {
      this.campaign.selectedDevices.push(device.id);
    })

    this.selected.emit(this.campaign.selectedDevices);
    this.validate();
  }
  updateTagSelection(device) {

  }

  validate() {
    if (this.campaign.selectedDevices.length > 0) {
      this.validated.emit('VALID');
    } else {
      this.validated.emit('INVALID');
    }
  }
}
