

<div class="devices-list" *ngIf="template==='table'">
    <div class="devices-list__header">
        <div class="devices-list__filters">
            <mat-form-field class="devices-list__search">
                <mat-label i18n>Hledej zařízení</mat-label>
                <input [ngModel]="filters.fulltext"  (keyup)="filterByFulltext($event.target['value'])" matInput i18n-placeholder placeholder="Jméno zařízení">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <button  class="devices-list__clear" *ngIf="hasFilters()" mat-button (click)="resetFilters()">Vymazat filtry</button>
            <app-tag-menu [selectedTags]="filters.tags" (selected)="filterByTags($event)" [tagType]="'devices'" class="devices-list__tag-menu" [selectable]="true" ></app-tag-menu>
        </div>
        <div class="devices-list__header__actions">
            <button *ngIf="devicePrivilege && !checkable" mat-raised-button (click)="openDeviceAuthorization()" color="primary"><span i18n>Přidat nové zařízení</span><mat-icon color="white">add</mat-icon></button>
            <div *ngIf="checkable" class="devices-list__count" i18n>Vybráno {{ selection.selected.length}} z {{dataSource.data.length}}</div>
        </div>
    </div>
    <div class="devices-list__wrapper" >
        <table mat-table [dataSource]="dataSource" matSort  matSortActive="id" matSortDisableClear matSortDirection="desc" class="mat-table--fullwidth devices-list__table" >
            <!-- Preview Column -->
            <ng-container matColumnDef="check" *ngIf="checkable">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                            color="primary"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let device" > 
                    <mat-checkbox  (click)="$event.stopPropagation()"
                        color="primary"
                        (change)="onSelectionChange($event, device)"
                        [checked]="selection.isSelected(device)"
                        [aria-label]="checkboxLabel(device)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Id</th>
                <td mat-cell *matCellDef="let device" ><span>{{device.id}}</span></td>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="branch">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Pobočka</th>
                <td mat-cell *matCellDef="let device" ><span>{{device.branch}}</span></td>
            </ng-container>    

            <!-- Title Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Název</th>
                <td mat-cell *matCellDef="let device" class="devices-list__item__name" (click)="openDeviceDetail(device.id)">
                    <h3 *ngIf="!device.clientName"> {{device.name}} </h3>
                    <h3 *ngIf="device.clientName"> {{device.clientName}} </h3>
                    <h3 *ngIf="isAdmin && device.clientName">&nbsp;({{device.clientName}})</h3>
                    
                </td>
            </ng-container>
            
            <!-- Tags Column -->
            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef i18n>Štítky</th>
                <td mat-cell *matCellDef="let device">
                    <app-tag-menu [selectable]="false" [groupCount]="3" [tags]="device.tags"></app-tag-menu>
                </td>
            </ng-container>

            <!-- Weight Type -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef i18n>Typ</th>
                <td mat-cell *matCellDef="let device"><h3> {{device.type}} </h3></td>
            </ng-container>
            
            <!-- state Column -->
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Stav</th>
                <td mat-cell *matCellDef="let device" class="devices-list__item__state"> <app-device-state [state]="device.state" ></app-device-state> </td>
            </ng-container>
            
            <!-- device Edits -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef i18n>Úpravy</th>
                <td mat-cell *matCellDef="let device"  width="80px">
                    <div class="devices-list__item__actions">
                        <button color="primary" class="mat-mdc-mini-fab--small" mat-mini-fab aria-label="Open preview" (click)="openPreview(device)" i18n-matTooltip matTooltip="Náhled kampaní">
                            <mat-icon>tv</mat-icon>
                        </button>
                        <button color="primary" class="mat-mdc-mini-fab--small" mat-mini-fab aria-label="Open timeline" (click)="openDeviceTimeline(device)" i18n-matTooltip matTooltip="Časová osa">
                            <mat-icon>subtitles</mat-icon>
                        </button>
                        <button color="primary" class="mat-mdc-mini-fab--small" mat-mini-fab aria-label="Edit device" (click)="openDeviceDetail(device.id)" i18n-matTooltip matTooltip="Úprava zařízení">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="isLoadingResults" class="devices-list__loading">
            <app-loading></app-loading>
        </div>
    </div>
</div>

<div class="devices-list devices-list--tiles" #tiles *ngIf="template==='tiles'">
    <div class="devices-list__header">
        <div class="devices-list__filters">
            <mat-form-field  class="devices-list__search">
                <mat-label i18n>Hledej zařízení</mat-label>
                <input [ngModel]="filters.fulltext" (keyup)="filterByFulltext($event.target['value'])" matInput i18n-placeholder  placeholder="Jméno zařízení">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <button  class="devices-list__clear" *ngIf="hasFilters()" mat-button (click)="resetFilters()">Vymazat filtry</button>
            <app-tag-menu [selectedTags]="filters.tags" (selected)="filterByTags($event)" [tagType]="'devices'" class="devices-list__tag-menu" [selectable]="true" ></app-tag-menu>
          </div>
    </div>
    <div class="devices-list__scroll">
        <div class="devices-list__wrapper" >
            <app-device-tile  class="devices-list__item" [device]="device"  *ngFor="let device of dataSource.filteredData"></app-device-tile>
        </div>
    </div>
</div>
