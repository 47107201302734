import { CommonModule } from "@angular/common";
import { BrowserModule, Title } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { MainNavigationComponent } from "./navigation/main-navigation/main-navigation.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CampaignsComponent } from "./campaigns/campaigns.component";
import { DevicesComponent } from "./devices/devices.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import localeCs from "@angular/common/locales/cs";
import { registerLocaleData } from "@angular/common";

/*material*/
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDividerModule } from "@angular/material/divider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSliderModule } from "@angular/material/slider";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressBarModule } from "@angular/material/progress-bar";

//custom modules
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxDropzoneModule } from "ngx-dropzone";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { SortablejsModule } from "ngx-sortablejs";
import { SharedModule } from "./shared/shared.module";
import { DevicesMapModule } from "./devices/devices-map/devices-map.module";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";

import { CampaignsListComponent } from "./campaigns/campaigns-list/campaigns-list.component";
import { DeviceStateComponent } from "./devices/device-state/device-state.component";
import { CampaignStepperComponent } from "./campaigns/campaign-stepper/campaign-stepper.component";
import { CampaignTypeComponent } from "./campaigns/campaign-type/campaign-type.component";
import { CampaignDateComponent } from "./campaigns/campaign-date/campaign-date.component";
import { CampaignContentComponent } from "./campaigns/campaign-content/campaign-content.component";
import { CampaignDevicesComponent } from "./campaigns/campaign-devices/campaign-devices.component";
import { CampaignRecapitulationComponent } from "./campaigns/campaign-recapitulation/campaign-recapitulation.component";
import { DevicesListComponent } from "./devices/devices-list/devices-list.component";
import { TimelineComponent } from "./schedule/timeline/timeline.component";
import { SchedulePlaylistComponent } from "./schedule/schedule-playlist/schedule-playlist.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { LoginComponent } from "./login/login.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { CampaignDetailComponent } from "./campaigns/campaign-detail/campaign-detail.component";
import { DeviceDetailComponent } from "./devices/device-detail/device-detail.component";
import { TagsListComponent } from "./tags/tags-list/tags-list.component";
import { TagsComponent } from "./tags/tags.component";
import { ClipsComponent } from "./clips/clips.component";
import { ClipsListComponent } from "./clips/clips-list/clips-list.component";
import { ClipUploadComponent } from "./clips/clip-upload/clip-upload.component";
import { ClipItemComponent } from "./clips/clip-item/clip-item.component";
import { ClipDetailComponent } from "./clips/clip-detail/clip-detail.component";
import { DayTimeComponent } from "./schedule/day-time/day-time.component";
import { GetClipMenuComponent } from "./clips/get-clip-menu/get-clip-menu.component";
import { ClipsSelectionComponent } from "./clips/clips-selection/clips-selection.component";
import { ConfirmDialogComponent } from "./utils/confirm-dialog/confirm-dialog.component";
import { TagAttachComponent } from "./tags/tag-attach/tag-attach.component";
import { CampaignNameComponent } from "./campaigns/campaign-name/campaign-name.component";
import { TagMenuComponent } from "./tags/tag-menu/tag-menu.component";
import { DeviceTileComponent } from "./devices/device-tile/device-tile.component";
import { ConvertDataSizesPipe } from "./pipes/convertors.pipe";
import { CampaignStateComponent } from "./campaigns/campaign-state/campaign-state.component";
import { TopBarComponent } from "./navigation/top-bar/top-bar.component";
import { CompanySelectComponent } from "./company/company-select/company-select.component";
import { CompanySelectDialogComponent } from "./company/company-select-dialog/company-select-dialog.component";
import { TagsFilterPipe } from "./tags/tags-filter.pipe";
import { DevicesMenuListComponent } from "./devices/devices-menu-list/devices-menu-list.component";
import { CustomMessageComponent } from "./utils/custom-message/custom-message.component";
import { EditorComponent } from "./editor/editor.component";
import { SaveSnackbarComponent } from "./utils/save-snackbar/save-snackbar.component";
import { DeleteSnackbarComponent } from "./utils/delete-snackbar/delete-snackbar.component";
import {
  DeviceAuthorizationComponent,
  DeviceAuthorizationSuccess,
} from "./devices/device-authorization/device-authorization.component";
import { ClipsMultipleActionsComponent } from "./clips/clips-multiple-actions/clips-multiple-actions.component";
import { ClipDetailFormsComponent } from "./clips/clip-detail-forms/clip-detail-forms.component";
import { PlaylistSelectComponent } from "./playlist/playlist-select/playlist-select.component";
import { CampaignPlaylistComponent } from "./campaigns/campaign-playlist/campaign-playlist.component";
import { PlaylistDetailComponent } from "./playlist/playlist-detail/playlist-detail.component";
import { PlaylistEditComponent } from "./playlist/playlist-edit/playlist-edit.component";
import { CurrentStatesComponent } from "./dashboard/current-states/current-states.component";
import { CampaignsMultipleActionsComponent } from "./campaigns/campaigns-multiple-actions/campaigns-multiple-actions.component";
import { CampaginsSelectionComponent } from "./campaigns/campagins-selection/campagins-selection.component";
import { SupportComponent } from "./support/support/support.component";
import { LoginRecoveryComponent } from "./login/login-recovery/login-recovery.component";
import { ScheduleTimeComponent } from "./schedule/schedule-time/schedule-time.component";
import { CampaignTimeComponent } from "./campaigns/campaign-time/campaign-time.component";
import { ModulesComponent } from "./modules/modules.component";
import { ContactDialogComponent } from "./utils/contact-dialog/contact-dialog.component";
import { LayoutSelectionComponent } from "./layouts/layout-selection/layout-selection.component";
import { PasswordChangeComponent } from "./login/password-change/password-change.component";
import { Error404Component } from "./errors/error404/error404.component";
import { UserComponent } from './user/user.component';
import { TwoFactorComponent } from './user/two-factor/two-factor.component';
import { AccountDeleteComponent } from './login/account-delete/account-delete.component';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { Error500Component } from './errors/error500/error500.component';


registerLocaleData(localeCs);

@NgModule({
  declarations: [
    AppComponent,
    MainNavigationComponent,
    DashboardComponent,
    CampaignsComponent,
    DevicesComponent,
    CampaignsListComponent,
    DeviceStateComponent,
    CampaignStepperComponent,
    CampaignTypeComponent,
    CampaignDateComponent,
    CampaignContentComponent,
    CampaignDevicesComponent,
    CampaignRecapitulationComponent,
    DevicesListComponent,
    TimelineComponent,
    SchedulePlaylistComponent,
    ScheduleComponent,
    LoginComponent,
    HomepageComponent,
    CampaignDetailComponent,
    DeviceDetailComponent,
    TagsListComponent,
    TagsComponent,
    ClipsComponent,
    ClipsListComponent,
    ClipUploadComponent,
    ClipItemComponent,
    ClipDetailComponent,
    DayTimeComponent,
    GetClipMenuComponent,
    ClipsSelectionComponent,
    ConfirmDialogComponent,
    TagAttachComponent,
    CampaignNameComponent,
    TagMenuComponent,
    DeviceTileComponent,
    ConvertDataSizesPipe,
    CampaignStateComponent,
    TopBarComponent,
    CompanySelectComponent,
    CompanySelectDialogComponent,
    TagsFilterPipe,
    DevicesMenuListComponent,
    CustomMessageComponent,
    EditorComponent,
    SaveSnackbarComponent,
    DeleteSnackbarComponent,
    DeviceAuthorizationComponent,
    DeviceAuthorizationSuccess,
    ClipsMultipleActionsComponent,
    ClipDetailFormsComponent,
    PlaylistSelectComponent,
    CampaignPlaylistComponent,
    PlaylistDetailComponent,
    PlaylistEditComponent,
    CurrentStatesComponent,
    CampaignsMultipleActionsComponent,
    CampaginsSelectionComponent,
    SupportComponent,
    LoginRecoveryComponent,
    ScheduleTimeComponent,
    CampaignTimeComponent,
    ModulesComponent,
    ContactDialogComponent,
    LayoutSelectionComponent,
    PasswordChangeComponent,
    Error404Component,
    UserComponent,
    TwoFactorComponent,
    AccountDeleteComponent,
    Error500Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    NgxDropzoneModule,
    NgxSliderModule,
    DragDropModule,
    HttpClientModule,
    ScrollingModule,
    FormsModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatDividerModule,
    MatDialogModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSliderModule,
    MatMenuModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    SortablejsModule.forRoot({ animation: 150 }),
    DevicesMapModule,
    NgxMatTimepickerModule.setLocale("cs-CZ"),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
